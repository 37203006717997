<template>
    <div class="foo_box">
        <div class="content">
            <div class="foo_info">
                <div class="foo_info_list">
                    <dl>
                        <dt>联系我们</dt>
                        <dd>扫码添加火键客户顾问咨询吧</dd>
                        <dd>
                            <img src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/wchat_logo.png"
                                width="48px" alt="" />
                        </dd>
                    </dl>
                </div>
                <div class="foo_info_list">
                    <dl>
                        <dt>关注我们</dt>
                        <dd>关注火键公众号了解更多干货吧</dd>
                        <dd>
                            <img src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/small_logo.png"
                                width="48px" alt="" />
                        </dd>
                    </dl>
                </div>
                <div class="foo_info_list">
                    <dl>
                        <dt>
                            <img src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/foo_logo.png"
                                width="120px" alt="" />
                        </dt>
                        <dd>智能获客平台</dd>
                        <dd>全域内容智能分发平台-赋能企业增长获客</dd>
                        <dd>粤ICP备2022154992号-2</dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Foo",

    data() {
        return {};
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less" scoped>
.foo_box {
    padding: 100px 0;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/fooBg.png);
    background-size: 100% 100%;
}

.content {
    max-width: 1360px;
    margin: 0 auto;
}

.foo_info {
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-top: 120px;

    dt {
        font-weight: bold;
        margin-bottom: 10px;
    }

    dd {
        font-size: 12px;

        &+dd {
            margin-top: 10px;
        }

        img {
            margin-top: 26px;
        }
    }
}
</style>