<style lang="less" scoped>
.main {
    position: relative;
}
.content {
    max-width: 1360px;
    margin: 0 auto;
}
.floating {
    position: fixed;
    bottom: 50px;
    // right: 0;
    z-index: 999;
}
</style>
<template>
    <div class="main" ref="main">
        <floating
            class="floating"
            :scrollTop="scrollTop"
            :style="{ left: floatingLeft + 20 + 'px' }"
            @backTop="backTop"
            @showReservation="showReservation"
        />
        <topBar :nail="true" backgroundColor="rgba(0, 0, 0, 0.1)" />
        <banner />
        <aboutSys />
        <div class="content">
            <authorization_data />
            <fans />
            <reservation
                style="margin-top: 20px"
                @hide_model="showReservation"
            />
        </div>
        <div ref="foo">
            <foo />
        </div>
        <!-- <el-dialog
            title="客服咨询"
            :visible.sync="shoReservation"
            top="10vh"
            width="800px"
        >
            <div>
                <reservation style="padding: 0" />
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
// import Bus from "@/utils/eventBus.js";
import authorization_data from "@/components/index/authorization_data.vue";
import topBar from "@/components/index/topbar.vue";
import aboutSys from "@/components/index/aboutSys.vue";
import fans from "@/components/index/fans_data.vue";
import banner from "@/components/index/banner.vue";
import foo from "@/components/index/foo.vue";
import reservation from "@/components/index/reservation.vue";
import floating from "@/components/floating.vue";
export default {
    name: "Index",
    components: {
        topBar,
        aboutSys,
        banner,
        fans,
        authorization_data,
        reservation,
        foo,
        floating,
    },
    data() {
        return {
            scrollTop: 0,
            shoReservation: false,
            floatingLeft: 0,
        };
    },
    created() {
        this.setFloating();
        this.scrollTop = document.documentElement.scrollTop;
        // Bus.$on("hide_model", (val) => {
        //     console.log(val);
        //     // this.showReservation(val);
        // });
    },
    mounted() {
        this.getFanportrait();
        window.addEventListener("scroll", () => {
            this.scrollTop = document.documentElement.scrollTop;
            // console.log(this.scrollTop);
        });
    },

    methods: {
        openUrl(url) {
            this.$router.push(url);
        },
        setFloating() {
            let bodyWidth = window.innerWidth;
            let contentWidth = 1360;
            let left = (bodyWidth - contentWidth) / 2 + contentWidth;
            this.floatingLeft = left;
        },
        backTop(target_num) {
            // document.documentElement.scrollTop = 0;
            let timer = setInterval(() => {
                // 获取当前滚动高度
                const top =
                    document.body.scrollTop ||
                    document.documentElement.scrollTop;
                // 设置滚动速度
                const speed = top / 4;
                if (document.body.scrollTop !== 0) {
                    document.body.scrollTop -= speed;
                } else {
                    document.documentElement.scrollTop -= speed;
                }
                if (top === 0) {
                    clearInterval(timer);
                }
            }, 16);
        },
        showReservation(val) {
            document.documentElement.scrollTop = 2730;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>