<template>
    <div class="hd" ref="hd">
        <div class="topbar">
            <h1 class="logo">
                <a href="javascript:;" title="火键剪辑">
                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/logo1.png"
                        alt=""
                    />
                </a>
            </h1>
            <div class="top_bar_right" :style="{ color }">
                <div class="btns">
                    <span class="icon_btn">
                        <i class="iconfont icon-a-sousuo"></i>
                    </span>
                    <span class="icon_btn">
                        <i class="iconfont icon-a-dianjishangchuan"></i
                    ></span>

                    <span
                        v-if="$route.name == 'index'"
                        class="nav_list"
                        @click="$router.push('/price')"
                    >
                        价格
                    </span>
                    <span v-else class="nav_list" @click="$router.push('/')">
                        首页
                    </span>
                </div>
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="$router.push('/login')"
                    v-if="!$store.state.token"
                    >登录</el-button
                >
                <span
                    v-else
                    class="username"
                    @click="$router.push('/home/user/index')"
                    >{{ $store.state.user.account }},hi~</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "topBar",
    props: ["nail", "backgroundColor", "color"],
    data() {
        return {};
    },

    mounted() {
        if (this.nail) {
            window.addEventListener("scroll", this.handleScroll);
        }
    },

    methods: {
        // handleScroll() {
        //     let scrollTop =
        //         window.pageYOffset ||
        //         document.documentElement.scrollTop ||
        //         document.body.scrollTop;
        //     let hd = this.$refs.hd;
        //     if (scrollTop >= 100) {
        //         hd.style.backgroundColor = `rgba(0, 0, 0,${
        //             scrollTop / (scrollTop + 50)
        //         }) `;
        //     } else if (scrollTop == 0) {
        //         hd.style.backgroundColor = "rgba(0,0,0,.3)";
        //     }
        // },
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="less" scoped>
.hd {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 54px;
    z-index: 999;
    background-color: #fff;
    .topbar {
        max-width: 1360px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo {
        line-height: 0;
    }
    img {
        width: 120px;
    }
}
.top_bar_right {
    display: flex;
    align-items: center;
}
.btns {
    display: flex;
    align-items: center;
}
.icon_btn {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    text-align: center;
    background-color: #f7f8fa;
    margin-right: 10px;
    .iconfont {
        font-size: 12px;
        color: #345582;
    }
}
.username {
    color: #1d2129;
}
.nav_list {
    // color: #3845ff;
    margin-right: 10px;
    font-weight: bold;
    color: #1d2129;
}
</style>