<style lang="less" scoped>
.reservation_box {
    background-color: #fff;
    padding: 50px 120px;
    text-align: center;
}
.logo img {
    width: 116px;
}
.describe {
    height: 50px;
    font-size: 26px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #1d2129;
    line-height: 50px;
    margin-top: 10px;
}
.title {
    height: 50px;
    font-size: 32px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #3845ff;
    line-height: 50px;
    margin-top: 20px;
}
.form {
    margin-top: 20px;
    .row1 {
        padding: 0 70px;
    }
    .form_row {
        display: flex;
        .item {
            flex: 1;
        }
    }
}
/deep/ .el-button--primary {
    background-color: #3845ff;
}
.btn_box {
    margin-top: 70px;
}
</style>
<template>
    <div class="reservation_box">
        <div class="logo">
            <img
                src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/logo_index.png"
                alt=""
            />
        </div>
        <div class="describe">企业级创意智能生产和协作增长平台</div>
        <div class="title">预约演练</div>
        <div class="form">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <div class="row1">
                    <el-form-item label="公司名称" prop="company">
                        <el-input
                            v-model="form.company"
                            placeholder="请输入公司名称"
                        ></el-input>
                    </el-form-item>
                </div>

                <div class="form_row">
                    <div class="item">
                        <el-form-item label="姓名" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入您的姓名"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="请输入您的手机号"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="btn_box">
                    <el-form-item>
                        <el-button
                            :loading="isSubmit"
                            type="primary"
                            @click="submitForm('form')"
                        >
                            <span v-if="isSubmit">提交中...</span>
                            <span v-else>立即预约</span>
                        </el-button>
                        <el-button @click="resetForm('form')">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { reservation } from "@/api/index.js";
import Bus from "@/utils/eventBus.js";
export default {
    name: "Reservation",

    data() {
        return {
            form: {
                name: "",
                company: "",
                phone: "",
            },
            rules: {
                company: [
                    {
                        required: true,
                        message: "请输入公司名称",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                ],
            },
            isSubmit: false,
        };
    },

    mounted() {
        // this.$emit("hide_model", false);
    },

    methods: {
        submitForm(formName) {
            // console.log(this.$parent);
            // this.$parent.showReservation(false);
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let data = this.form;
                    this.isSubmit = true;
                    try {
                        let res = await reservation(data);
                        if (res.code == 200) {
                            this.$alert("演示预约已提交，请等待客服人员联系", {
                                confirmButtonText: "确定",
                            });

                            Bus.$emit("hide_model", false);
                        } else {
                            this.$message.error("提交失败");
                        }
                    } catch (error) {
                        this.$message.error("此功能暂未开放");
                    }
                    this.isSubmit = false;
                    // console.log(11);
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>