<template>
    <div class="floating_box">
        <transition name="el-fade-in-linear">
            <div
                class="floating_btn back_top"
                v-show="scrollTop > 200"
                @click="backTop"
            >
                <i class="iconfont icon-shengdanjianhuahuidaodingbu"></i>
            </div>
        </transition>
        <transition name="el-fade-in-linear">
            <div
                class="floating_btn show_model"
                @click="show_model"
                v-show="scrollTop < 2730"
            >
                <i class="iconfont icon-zaixianzixun"></i>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "floating",
    props: ["scrollTop"],
    data() {
        return {};
    },

    mounted() {},

    methods: {
        backTop() {
            this.$emit("backTop", 0);
        },
        show_model() {
            this.$emit("showReservation", true);
        },
    },
};
</script>

<style lang="less" scoped>
.floating_box {
    width: 50px;
    // height: 100px;
    // display: flex;
    flex-direction: column;
    background-color: #5068da;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    .floating_btn {
        // flex: 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #3845ff;
        }
        .iconfont {
            color: #fff;
            font-size: 22px;
        }
    }
}
</style>